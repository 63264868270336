<template>
<router-view />
</template>

<script>
import "@/assets/scss/App.scss";
export default {
  name: 'App'
}
</script>
