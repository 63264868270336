<template>
<div id="hero" class="d-flex justify-content-center align-items-end">
  <div class="video-wrapper">
    <video autoplay loop muted playsinline preload="metadata" :src="src" type="video/mp4" :poster="poster"></video>
  </div>
</div>
</template>

<script>
export default {
  name: 'Hero',
  data: function() {
    return {
      src: require('@/assets/media/ProvidenceAnimation_1920x1080.mp4'),
      poster: require('@/assets/img/bkg_main.jpg'),
      width: document.documentElement.clientWidth
    }
  },
  mounted() {
    var mobileScreen = window.matchMedia('(max-width: 580px)');
    //var tabletScreen = window.matchMedia('(max-width: 1024px)');
    let img = new Image();
    img.src = require('@/assets/img/bkg_main.jpg');
    img.onload = () => {
      let elem = document.getElementById("app");
      elem.classList.add("ready");
      setTimeout(() => {
        var views = document.getElementsByClassName("inview");
        for (var i = 0; i < views.length; i++) {
          let easeType = (views[i].hasAttribute('data-ease')) ? views[i].getAttribute('data-ease') : "fadeInUp";
          views[i].classList.add(easeType);
        }
      }, 1000);
    }
    window.addEventListener('resize', this.setVideoSize);
    if (mobileScreen.matches) {
      this.$data.src = require('@/assets/media/ProvidenceAnimation_480x1080.mp4');
      this.$data.poster = require('@/assets/img/bkg_main_mobile.jpg');
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.getDimensions);
  },
  methods: {
    setVideoSize() {
      if (document.documentElement.clientWidth <= 580) {
        this.$data.src = require('@/assets/media/ProvidenceAnimation_480x1080.mp4');
        this.$data.poster = require('@/assets/img/bkg_main_mobile.jpg');
      } else {
        this.$data.src = require('@/assets/media/ProvidenceAnimation_1920x1080.mp4');
        this.$data.poster = require('@/assets/img/bkg_main.jpg');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$mobile: 767px;
$desktop: 1399px;
$tablet: 991px;
$phone: 580px;
#hero {
    position: relative;
    height: 100vh;
    overflow: hidden;
    //background: url("../assets/img/bkg_main.jpg") no-repeat center top / cover;
    @media(max-width: $tablet) {
        //background-image: url("../assets/img/bkg_main_tablet.jpg");
    }
    @media(max-width: $phone) {
        //background-image: url("../assets/img/bkg_main_mobile.jpg");
    }
    .video-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        pointer-events: none;
        overflow: hidden;
        video {
            width: 100vw;
            height: 56.25vw;
            min-height: 100vh;
            min-width: 177.77vh;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            @media(max-width: 580px) {
                min-width: inherit;
                height: auto;
            }
        }
    }
}
</style>
