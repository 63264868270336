<template>
<div id="thankyou-overlay" class="collapse fade show" @click="triggerClose"></div>
<div id="thankyou-page" class="collapse fade show">
  <div class="bkg-image">
    <div class="inner">
      <img class="img-fluid" src="@/assets/img/bg_thankyou.png" alt="Thank You" />
      <button type="button" id="btn-close" @click="triggerClose"></button>
    </div>
  </div>
  <div class="content h-100 mx-auto text-center">
    <div class="d-flex h-100 align-items-center">
      <div class="wrapper mx-auto">
        <h2 class="inview animated inview animated slow delay-1">THANK YOU</h2>
        <h4 class="pt-md-2 inview animated slow delay-2">FOR SUBSCRIBING TO OUR NEWSLETTER.</h4>
        <div class="icon inview animated slow delay-3"><img class="img-fluid" src="@/assets/img/icon_logo.svg" alt="Thank you for subscribing to our newsletter."></div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Thankyou',
  methods: {
    triggerClose: () => {
      var overlay = document.getElementById('thankyou-overlay');
      overlay.classList.remove("show");
      var page = document.getElementById('thankyou-page');
      page.classList.remove("show");
    }
  }
}
</script>

<style lang="scss" scoped>
#thankyou-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.5);
}
#thankyou-page {
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    z-index: 11;
    margin: auto;
    transform: translateY(-50%);
    webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transition: transform 0.4s ease;
    .bkg-image {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        max-width: 660px;
        transform: translateY(-50%);
        webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        @media(max-width: 991px) {
            max-width: 580px;
        }
        .inner {
            margin: 2rem;
        }
    }
    .content {
        padding: 6rem 4rem;
        @media(max-width: 991px) {
            padding: 6rem 3rem;
        }
        color: #fff;
        h2 {
            font-size: 3.6rem;
            letter-spacing: 0.5rem;
            @media(max-width: 991px) {
                font-size: 3rem;
            }
            @media(max-width: 767px) {
                font-size: 2.6rem;
                letter-spacing: 0.4rem;
            }
            @media(max-width: 520px) {
                font-size: 7.5vw;
                letter-spacing: 0.2rem;
            }
        }
        h4 {
            color: #7f8ba5;
            margin-top: 1.5rem;
            font-size: 1.1rem;
            line-height: 1.2;
            @media(max-width: 1199px) {
                margin-top: 1rem;
                font-size: 1rem;
            }
            @media(max-width: 767px) {
                font-size: 0.9rem;
            }
            @media(max-width: 520px) {
                font-size: 0.85rem;
            }
        }
        .icon {
            text-align: center;
            width: 3.2vw;
            max-width: 3rem;
            margin: auto;
            position: relative;
            top: 1rem;
            @media(max-width: 767px) {
                top: 0.5rem;
                width: 5.8vw;
            }
        }
    }
    #btn-close {
        position: absolute;
        top: 2rem;
        left: 2rem;
        z-index: 2;
        display: inline-block;
        width: 6.8rem;
        height: 6.8rem;
        overflow: hidden;
        border: 0;
        background: transparent;
        @media(max-width: 767px) {
            width: 9.7vw;
            height: 9.7vw;
        }
    }
}
</style>
