<template>
<Hero />
<Footer />
<Thankyou v-if="openThankyou" />
</template>

<script>
import Hero from '@/components/Hero.vue'
import Footer from '@/components/Footer.vue'
import Thankyou from '@/components/Thankyou.vue'

export default {
  name: 'Home',
  components: {
    Hero,
    Footer,
    Thankyou
  },
  data: () => {
    return {
      openThankyou: false
    }
  },
  mounted() {
    this.openThankyou = (window.location.pathname == '/thankyou') ? true : false;
  }
};
</script>
