import {createRouter, createWebHistory} from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    title: process.env.VUE_APP_SITE_NAME,
    component: Home
  },
  {
    path: "/thankyou",
    name: "Thank You",
    component: Home
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {selector: to.hash, behavior: "smooth"};
    } else {
      return {x: 0, y: 0};
    }
  }
});

router.beforeEach((to, from, next) => {
  var mainTitle = to.title
    ? to.title + " | " + process.env.VUE_APP_SITE_NAME
    : to.name + " | " + process.env.VUE_APP_SITE_NAME;
  document.title = mainTitle;
  next();
});

export default router;
