<template>
<form class="signup-form" @submit="subscribe">
  <div class="input-group my-3">
    <input class="form-control" type="email" autocapitalize="off" autocorrect="off" name="EMAIL" id="mce-EMAIL" placeholder="EMAIL" required />
    <input type="hidden" name="b_a6618b2da9deffc3f26f24bf7_0aac10d2bd" tabindex="-1" value="">
    <button class="btn btn-theme input-group-text signup" type="submit">SIGN UP</button>
  </div>
  <div class="message"></div>
</form>
</template>

<script>
import $ from 'jquery';
export default {
  name: 'Signup',
  methods: {
    subscribe: function(e) {
      e.preventDefault();
      var url = 'https://dynastystudios.us6.list-manage.com/subscribe/post-json?u=a6618b2da9deffc3f26f24bf7&id=0aac10d2bd';
      $.ajax({
        type: 'POST',
        url: url,
        data: $(e.target).serialize(),
        cache: false,
        dataType: "jsonp",
        jsonp: "c", // trigger MailChimp to return a JSONP response
        contentType: "application/json; charset=utf-8",
        headers: {
          'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        error: function() {
          console.error("Could not connect to the registration server. Please try again later.");
        },
        success: function(data) {
          if (data.result == "success") {
            $('.message').text('Thank you for signing up, please check your email to confirm subscription.');
            $('input-group').remove();
          } else {
            if (data.result == "error") {
              $('.message').html(data.msg);
            } else {
              $('.message').text('Sorry there\'s a problem sending your request. Please try again.');
            }
          }
        }
      });
      return false;
    }
  }
}
</script>

<style lang="scss" scoped>
$mobile: 767px;
$phone: 580px;
.signup-form {
    .input-group {
        position: relative;
        .form-control {
            position: relative;
            z-index: 0;
            border-radius: 50px !important;
            border: 1px solid #fff;
            padding: 0.5rem 1.2rem;
            font-size: 1.1rem;
            letter-spacing: 0.1rem;
            color: #fff;
            @media (max-width: $mobile) {
                padding: 0.4rem 1.2rem;
            }
            @media (max-width: $phone) {
                padding: 0.52rem 1rem;
                font-size: 0.9rem;
            }
        }
        .signup {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 10;
            border-radius: 50px !important;
            border: 2px solid #fff;
            @media (max-width: $mobile) {
                padding: 0.55rem 1rem;
            }
            @media (max-width: $phone) {
                font-size: 0.95rem;
                padding: 0.7rem 1rem 0.4rem;
            }
        }
    }
    .message {
        color: #ff818c;
        text-transform: uppercase;
        text-align: center;
        text-shadow: 2px 2px 6px black;
        font-weight: 600;
        margin-bottom: 0.8rem;
    }
}
</style>
